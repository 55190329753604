<template>
    <div id="SolutionThree">
      <!-- SolutionThree头部内容 -->
      <div class="SolutionThreeHeader">
        <!-- 头部组件 -->
        <Header />
      </div>
      <!-- SolutionThree中间内容 -->
      <div class="SolutionThreeCenter">
        <!-- 产品简介 -->
        <div class="producrPro">
          <div class="protectProTit">产品简介</div>
          <div class="protectProCon">
            <img src="~assets/img/SolutionThree/产品简介@2x.png" alt="" />
            <ul class="producrProRight">
              <li v-for="(item, index) in producrProRight" :key="index">
                <div class="producrProRiTit">{{ item.Title }}</div>
                <div class="producrProRiCon">
                  {{ item.Text }}
                </div>
              </li>
            </ul>
          </div>
          <ul class="rate">
            <li v-for="(item, index) in rate" :key="index">
              <div class="rateNum">
                {{ item.num }}
              </div>
              <div class="ratePro">{{ item.text }}</div>
            </li>
          </ul>
        </div>
        <!-- 产品价值 -->
  
        <div class="MathCompany">
          <div class="MathCompanyTit">
            产品价值
          </div>
          <img
            class="MathCompanyImg"
            src="~assets/img/SolutionThree/产品价值@2x.png"
            alt=""
          />
          <ul class="MathCompanyDetail">
            <li v-for="(item, index) in MathCompanyDetail" :key="index">
              <div class="headTop">
                <div class="headTopTit">
                  {{ item.Titles }}
                </div>
                <img class="MathComIcons" :src="item.icons" alt="" />
              </div>
              <!-- <div class="MathComConTie">
                {{ item.Title }}
              </div> -->
              <div class="MathComContent" v-html="item.text"></div>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
      <!-- 回到顶部 -->
      <el-backtop
        ><img
          src="~assets/img/SolutionThree/icon-一键置顶@2x.png"
          alt="icon-回到顶部@2x.png"
      /></el-backtop>
    </div>
  </template>
  <script>
  import Header from "components/Header/dsHeader";
  import Footer from "components/Footer/dsFooter";
  export default {
    name: "SolutionThree",
    data() {
      return {
        producrProRight: [
          {
            Title: "产品特点",
            Text:
              " 汽船配数字化管控系统主要面向汽船配行业企业，贯穿了工艺设计、规划、验证、直至车间生产工艺整个制造过程，改善生产组织与管理，提高制造系统的柔性，提供设定的效率。 "
          },
          {
            Title: "产品优势",
            Text:
              "通过多系统集成、APS智能排产的方式及时有效的跟踪订单，避免生产的延误；MES制造执行提供数据支撑；QIS系统监控和分析采购、制造过程的质量信息，帮助企业加强质量问题的监督管理，提升产品质量，打造一个高标准的数字化车间。"
          }
        ],
        MathCompanyDetail: [
          {
            Titles: "企业总线ESB",
            icons: require("assets/img/SolutionThree/icon-ESB@2x.png"),
            text: "单点登录，集成PLM、ERP、MES、APS、OA、QIS；统一的数据获取、转换、分发的数据交换架构。"
          },
          {
            Titles: "MES",
            icons: require("assets/img/SolutionThree/icon-MES@2x.png"),
            text: "管控主要工序，支持单件流、批次流、混合流的生产模式；<br>支持对产品进行追溯，查询批次所有的生产过程、检验记录、扣料批次等。"
          },
          {
            Titles: "SCADA",
            icons: require("assets/img/SolutionThree/icon-SCADA@2x.png"),
            text: "设备联网采集与监控。"
          },
          {
            Titles: "APS智能排程",
            icons: require("assets/img/SolutionThree/icon-排程@2x.png"),
            text: "自动排程、可干预、可视化；<br>从多维度查看排程结果。"
          },
          {
            Titles: "员工绩效管理",
            icons: require("assets/img/SolutionThree/icon-绩效oa@2x.png"),
            text:
              " 员工计件、计时工资自动核算。"
          },
          {
            Titles: "QIS质量管理",
            icons: require("assets/img/SolutionThree/icon-品质管理@2x.png"),
            text:
              "建立全面的质量管控体系。"
          },
          {
            Titles: "设备维修管理",
            icons: require("assets/img/SolutionThree/icon-可视化@2x.png"),
            text:
              "滴滴抢单模式，提高维修效率，有效减少维修人员。"
          },
          {
            Titles: "可视化",
            icons: require("assets/img/SolutionThree/icon-可视化@2x.png"),
            text:
              "生产管理人员通过车间看板掌控工厂生产现场信息；<br>生产现场人员实时掌控现场信息，提升效率。"
          }
        ],
        rate: [
          { num: "23%", text: "生产的周期缩短" },
          { num: "80%", text: "生产数据准确性提升" },
          { num: "65%", text: "数据汇总和分析时间降低" },
          { num: "13%", text: " 降低物料库存数" },
          { num: "15%", text: " 个人绩效提高" },
        ]
      };
    },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
    components: {
      Header,
      Footer
    }
  };
  </script>
  <style lang="less" scoped>
  #SolutionThree {
    width: 1583px;
    background-color: #f8f9fb;
    margin: 0 auto;
    // SolutionThree头部样式
    .SolutionThreeHeader {
      width: 1583px;
      height: 620px;
      background: url("~assets/img/SolutionThree/banner@2x.png") no-repeat;
       background-size: 1583px 620px;
      background-position: center;
      margin: 0 auto;
    }
    //   SolutionThree中间内容
    .SolutionThreeCenter {
      width: 1220px;
      margin: 80px auto 102px;
      // 产品简介
      .producrPro {
        .protectProTit {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 27px;
          color: rgba(51, 51, 51, 0.85);
          text-align: center;
        }
        .protectProCon {
          height: 390px;
          margin-top: 30px;
          display: flex;
          // overflow: hidden;
          img {
            width: 572px;
            height: 390px;
            border-radius: 10px;
          }
          .producrProRight {
            height: 390px;
            padding: 0;
            margin: 0px 0px 0px 48px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-content: center;
            li {
              &:first-child {
                margin-bottom: 30px;
              }
              .producrProRiTit {
                width: 92px;
                height: 36px;
                background: #e7f3fe;
                border-radius: 6px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 36px;
                color: #1e90ff;
                text-align: center;
              }
              .producrProRiCon {
                width: 600px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 28px;
                color: rgba(51, 51, 51, 0.85);
                margin-top: 14px;
              }
            }
          }
        }
        .rate {
          height: 142px;
          background: #fff;
          margin-top: 20px;
          padding: 0;
          display: flex;
          li {
            width: 305px;
            height: 142px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .rateNum {
              font-size: 46px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #1e90ff;
            }
            .ratePro {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
              margin-top: 14px;
            }
          }
        }
      }
      // 产品价值
      .MathCompany {
        margin-top: 80px;
        .MathCompanyTit {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 27px;
          color: rgba(51, 51, 51, 0.85);
          text-align: center;
        }
        .MathCompanyImg {
          width: 1220px;
          height: 500px;
          margin-top: 30px;
        }
        .MathCompanyDetail {
          height: 544px;
          margin-top: 30px;
          padding: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          li {
            width: 390px;
            height: 202px;
            background-color: #fff;
            margin-right: 25px;
            &:nth-child(-n + 3) {
              height: 202px;
            }
            &:nth-child(n + 4) {
              height: 146px;
            }
            &:nth-child(3n) {
              margin-right: 0px;
            }
  
            .headTop {
              position: relative;
              overflow: hidden;
              display: flex;
              height: 58px;
              background: url("~assets/img/SolutionThree/BG@2x.png") no-repeat;
              background-size: cover;
              .headTopTit {
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 28px;
                color: #1e90ff;
                margin: 16px 0 0 30px;
              }
              .MathComIcons {
                position: absolute;
                width: 36px;
                height: 36px;
                top:50%;
                right: 30px;
                transform: translate(0,-50%);
                 
              }
            }
            /* .MathComConTie {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: rgba(51, 51, 51, 0.85);
              margin: 20px 0 0 30px;
            } */
            .MathComContent {
              height: 70px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 28px;
              color: rgba(51, 51, 51, 0.85);
              margin: 20px 20px 0 30px;
            }
          }
        }
      }
    }
  }
  </style>
  <style lang="less">
  .el-backtop {
    img {
      width: 30px;
      height: 30px;
    }
  }
  </style>
  